<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <!-- 路由占位符 -->
      <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh')
export default {
  name: 'app',
  data() {
    return {
      locale: zhCN,
      moment,
      zhCN
    }
  }
}
</script>

<style></style>
