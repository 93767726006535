<template>
  <div class="body">
    <Header></Header>
    <div class="container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Header from './component/header'
export default {
  components: {
    Header
  },
  data() {
    return {}
  }
}
</script>

<style>
.body {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  min-width: 1400px;
  height: 100%;
}
.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 1400px;
  padding: 80px 20px 20px 20px;
  box-sizing: border-box;
  background: #f7f8fb;
}
</style>
