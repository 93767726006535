import Vue from 'vue'
import {
  Pagination,
  Button,
  Form,
  Input,
  FormModel,
  Layout,
  Row,
  Col,
  Menu,
  Icon,
  Table,
  Breadcrumb,
  Select,
  Card,
  DatePicker,
  ConfigProvider,
  Modal,
  Tabs,
  Upload,
  message,
  Popconfirm,
  Checkbox,
  Switch,
  Radio
} from 'ant-design-vue'

// 导入弹框组件

Vue.use(Pagination)
Vue.use(Button)
Vue.use(Form)
Vue.use(Form.Item)
Vue.use(Input)
Vue.use(FormModel)
Vue.use(Layout)
Vue.use(Layout.Sider)
Vue.use(Layout.Header)
Vue.use(Layout.Content)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Menu.Item)
Vue.use(Menu.SubMenu)
Vue.use(Menu.ItemGroup)
Vue.use(Icon)
Vue.use(Table)
Vue.use(Table.Column)
Vue.use(Breadcrumb)
Vue.use(Breadcrumb.Item)
Vue.use(Select)
Vue.use(Card)
Vue.use(Select.Option)
Vue.use(Input.Group)
Vue.use(DatePicker)
Vue.use(DatePicker.RangePicker)
Vue.use(ConfigProvider)
Vue.use(Modal)
Vue.use(Tabs)
Vue.use(Tabs.TabPane)
Vue.use(Upload)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Form)
Vue.use(Form.Item)
Vue.use(Input)
Vue.use(FormModel)
Vue.use(Layout)
Vue.use(Layout.Sider)
Vue.use(Layout.Header)
Vue.use(Layout.Content)
Vue.use(Row)
Vue.use(Col)
Vue.use(Menu)
Vue.use(Menu.Item)
Vue.use(Menu.SubMenu)
Vue.use(Menu.ItemGroup)
Vue.use(Icon)
Vue.use(Table)
Vue.use(Table.Column)
Vue.use(Breadcrumb)
Vue.use(Breadcrumb.Item)
Vue.use(Select)
Vue.use(Card)
Vue.use(Select.Option)
Vue.use(Input.Group)
Vue.use(DatePicker)
Vue.use(DatePicker.RangePicker)
Vue.use(ConfigProvider)
Vue.use(Modal)
Vue.use(Tabs)
Vue.use(Tabs.TabPane)
Vue.use(Popconfirm)
Vue.use(Checkbox)
Vue.use(Switch)
Vue.use(Radio)
Vue.prototype.$message = message
Vue.prototype.$confirm = Popconfirm.confirm
