import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../view/Login.vue'
import Layout from '@/layout'
import local from '@/utils/local'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: '/member',
    children: [
      {
        path: '/member',
        name: 'member',
        redirect: '/member/user',
        meta: { title: '党员评价系统' },
        component: () => import('../view/member'),
        children: [
          {
            path: '/member/user',
            name: 'memberuser',
            meta: { title: '用户管理' },
            redirect: '/member/user/list',
            component: () => import('../view/member/user'),
            children: [
              {
                path: '/member/user/list',
                meta: { title: '用户管理列表' },
                name: 'memberuserlist',
                component: () => import('../view/member/user/list')
              }
            ]
          },
          {
            path: '/member/task',
            name: 'membertask',
            meta: { title: '任务管理' },
            redirect: '/member/task/list',
            component: () => import('../view/member/task'),
            children: [
              {
                path: '/member/task/list',
                meta: { title: '任务管理列表' },
                name: 'membertasklist',
                component: () => import('../view/member/task/list')
              },
              {
                path: '/member/task/addtask',
                meta: { title: '新增/编辑任务' },
                name: 'membertaskaddtask',
                component: () => import('../view/member/task/addtask')
              },
              {
                path: '/member/task/watch',
                name: 'membertaskwatch',
                meta: { title: '任务明细' },
                redirect: '/member/task/watch/list',
                component: () => import('../view/member/task/watch'),
                children: [
                  {
                    path: '/member/task/watch/list',
                    name: 'membertaskwatchlist',
                    meta: { title: '查看明细列表' },
                    component: () => import('../view/member/task/watch/list')
                  },
                  {
                    path: '/member/task/watch/watchinfo',
                    name: 'membertaskwatchwatchinfo',
                    meta: { title: '查看' },
                    component: () => import('../view/member/task/watch/watchinfo')
                  }
                ]
              }
            ]
          },
          {
            path: '/member/rank',
            name: 'memberrank',
            meta: { title: '排行榜单' },
            redirect: '/member/rank/list',
            component: () => import('../view/member/rank'),
            children: [
              {
                path: '/member/rank/list',
                name: 'memberranklist',
                meta: { title: '榜单列表' },
                component: () => import('../view/member/rank/list')
              }
            ]
          }
        ]
      }
    ]
  },
  { path: '/login', component: Login, meta: { title: '党员评价系统' } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = local.get('token')
  document.title = to.meta.title
  if (token) {
    next()
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
