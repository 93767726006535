import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 导入全局样式表
import '@/assets/css/globle.css'
// 导入antcss
import 'ant-design-vue/dist/antd.css'
// 导入ant ui
import '@/plugins/ant-design-vue.js'
// 导入动态title
Vue.config.productionTip = false
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
