// 验证手机号
export function phoneCheck(rule, value, callbackFn) {
  if (value) {
    const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
    if (!reg.test(value)) {
      callbackFn('请输入正确手机号码')
      return
    }
  }
  callbackFn()
}

// 验证金额
export function validatorMoney(rule, value, callbackFn) {
  if (value) {
    const reg = /^\d+$|^\d*\.\d+$/g
    if (!reg.test(value)) {
      callbackFn('请输入正确金额')
      return
    }
  }
  callbackFn()
}

// 验证金额
export function validatorMoney2(rule, value, callbackFn) {
  if (value) {
    const reg = /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,7}\.\d{0,2}$|^[1-9]\d{0,7}$/
    if (!reg.test(value)) {
      callbackFn('请输入8位整数加2位小数')
      return
    }
  }
  callbackFn()
}

// 验证金额
export function validatorMoney3(rule, value, callbackFn) {
  if (value) {
    const reg = /^0\.([1-9]|\d[1-9]|\d\d[1-9]|\d\d\d[1-9])$|^[1-9]\d{0,7}\.\d{0,4}$|^[1-9]\d{0,7}$/
    if (!reg.test(value)) {
      callbackFn('请输入8位整数加4位小数')
      return
    }
  }
  callbackFn()
}

// 不允许出现中文
export function changeChar(rule, value, callbackFn) {
  if (value) {
    const reg = /[\u4E00-\u9FA5]/g
    if (reg.test(value)) {
      callbackFn('不允许输入中文字符')
      return
    }
  }
  callbackFn()
}

// 只允许输入数字
export function onlyNumber(rule, value, callbackFn) {
  if (value) {
    const reg = /[^\d]/g
    if (reg.test(value)) {
      callbackFn('只允许输入数字')
      return
    }
  }
  callbackFn()
}

// 只允许输入数字
export function onlyNumberEg(rule, value, callbackFn) {
  if (value) {
    const reg = /[0-9a-zA-Z]/g
    if (!reg.test(value)) {
      callbackFn('只允许输入英文和数字')
      return
    }
  }
  callbackFn()
}
// 验证大于0的数字
export function checkMoney(rule, value, callbackFn) {
  if (value) {
    const regMoney = /\d+(\.\d{0,2})?/
    if (!regMoney.test(value)) {
      callbackFn('请输入大于0的数字')
      return
    }
  }
  callbackFn()
}
// 验证正整数
export function checkCourse(rule, value, callbackFn) {
  if (value) {
    const regCourse = /^(-|\+)?\d+$/
    if (!regCourse.test(value)) {
      callbackFn('请输入正确格式')
      return
    }
  }
  callbackFn()
}
// 用户名称
export function checkName(rule, value, callbackFn) {
  if (value) {
    const regName = /^[a-zA-Z\u4e00-\u9fa5]+$/
    if (!regName.test(value)) {
      callbackFn('请输入中文或英文')
      return
    }
  }
  callbackFn()
}
// 类似于机构名称
export function checkJgname(rule, value, callbackFn) {
  if (value) {
    const regJgname = /^[\u4E00-\u9FA5A-Za-z0-9]+$/
    if (!regJgname.test(value)) {
      callbackFn('请输入中文,英文，数字')
      return
    }
  }
  callbackFn()
}
