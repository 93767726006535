import axios from 'axios'
import local from './local'
import { message } from 'ant-design-vue'
import router from '../router'
// import qs from 'qs'

axios.defaults.timeout = 8000
axios.defaults.baseURL = process.env.VUE_APP_URL

const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。'
}

// 请求拦截器
axios.interceptors.request.use((config) => {
  config.headers.Authorization = 'Basic ' + 'bGFtcF93ZWI6bGFtcF93ZWJfc2VjcmV0' // 让每个请求携带自定义token
  config.headers.tenant = local.get('tenant') || null
  const token = local.get('token')
  console.log(token, 'token')
  // config.url = `https://wrdy.ms-cloud.cn/api${config.url}`
  // config.url = `http://192.168.3.73:8085${config.url}`
  if (token) {
    config.headers.Authorization = 'Bearer ' + token
  }
  return config
})

// 路由白名单（没有返回code）
const whiteList = []

// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    const code = response.data.code
    // 判断是否是导出的接口
    if (whiteList.indexOf(response.config.url) !== -1) {
      return response
    } else {
      if (code) {
        if (code === 401) {
          // token失效返回登录
          message.warning('登录状态已过期，请重新登录')
          localStorage.removeItem('token')
          localStorage.removeItem('role')
          localStorage.removeItem('username')
          location.href = '/login'
        } else if (code === -10) {
          message.error(response.data.msg)
        } else if (code !== 200) {
          if (response.data.message) {
            message.error(response.data.message || '接口错误，请联系管理员')
          }
          return Promise.reject(response.data.message)
        } else {
          return response.data
        }
      } else {
        return response.data
      }
    }
  },
  (error) => {
    let code = 0
    try {
      code = error.response.status
    } catch (e) {
      if (error.toString().indexOf('Error: timeout') !== -1) {
        message.error('网络请求超时')
        return Promise.reject(error)
      }
      if (error.toString().indexOf('Error: Network Error') !== -1) {
        message.error('网络请求错误')
        return Promise.reject(error)
      }
    }
    if (error.response.data.code === -9) {
      message.error(error.response.data.msg)
    } else if (error.response.data.code === 40001 || error.response.data.code === 40009) {
      router.push('/login')
      message.error(error.response.data.msg)
    } else {
      if (error.response.data.msg) {
        message.error(error.response.data.msg)
      } else {
        message.error(codeMessage[code])
      }
    }
    return Promise.reject(error)
  }
)

export default {
  get(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params
        })
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
        })
    })
  },
  post(url, params, header) {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params, header)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
        })
    })
  },
  put(url, params) {
    return new Promise((resolve, reject) => {
      axios
        .put(url, params)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
        })
    })
  },
  delete(url, data) {
    return new Promise((resolve, reject) => {
      axios
        .delete(url, data)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          console.log(err)
        })
    })
  }
}
