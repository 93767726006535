<template>
  <div class="login_container">
    <div class="header_img">
      <img src="../assets/images/logo.png" alt="" />
    </div>
    <div class="form">
      <div class="login_form">
        <div class="head">
          <img src="../assets/images/login.png" alt="" />
          <span>管理员账号登录</span>
        </div>
        <a-form-model :model="loginForm" ref="loginRef" :rules="loginRules" class="forminput">
          <a-form-model-item prop="username">
            <a-input placeholder="请输入管理员账号" v-model="loginForm.username"></a-input>
          </a-form-model-item>
          <a-form-model-item prop="password">
            <a-input placeholder="请输入登录密码" type="password" v-model="loginForm.password"></a-input>
          </a-form-model-item>
        </a-form-model>
        <a-button @click="login" @keyup.enter="login">登录</a-button>
      </div>
      <div class="bottom_desc">
        <div>Copyright 2018 武汉软件工程职业学院 版权所有 All Rights Reserved</div>
        <div style="text-align:center;padding-top:10px">由武汉梦软科技有限公司提供技术支持</div>
      </div>
    </div>
  </div>
</template>

<script>
import local from '@/utils/local'
import { login } from '@/api/login'
import { changeChar } from '@/utils/validator'
export default {
  data() {
    return {
      // 登录表单
      loginForm: {
        code: '',
        loginType: 'admin',
        username: '',
        password: ''
      },
      // 登录表单校验
      loginRules: {
        username: [{ required: true, message: '请输入登录账号' }, { validator: changeChar }, { min: 3, max: 11, message: '登录长度3~11个字符' }],
        password: [{ required: true, message: '请输入登录密码' }, { validator: changeChar }, { min: 3, max: 20, message: '登录密码长度3~20个字符' }]
      }
    }
  },
  methods: {
    login() {
      this.$refs.loginRef.validate(async (valid) => {
        if (valid) {
          const data = await login({
            username: this.loginForm.username,
            password: this.loginForm.password,
            loginType: this.loginForm.loginType,
            code: this.loginForm.code
          })
          if (data.code === 200) {
            local.set('token', data.data.token)
            this.$message.success('登录成功!')
            this.$router.push('/member/user')
          } else {
            this.$message.error('登录失败!')
          }
        }
      })
    },
    // 点击回车键登录
    keyDown(e) {
      // 回车则执行登录方法 enter键的ASCII是13
      if (e.keyCode === 13) {
        this.login() // 定义的登录方法
      }
    }
  },
  mounted() {
    // 绑定监听事件
    window.addEventListener('keydown', this.keyDown)
  },
  destroyed() {
    // 销毁事件
    window.removeEventListener('keydown', this.keyDown, false)
  }
}
</script>

<style scoped>
.login_container {
  height: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
}
.header_img {
  width: 100%;
  height: 60px;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.login_form {
  width: 760px;
  height: 470px;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.form {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 80px;
  flex-direction: column;
}
.login_form > img {
  width: 89px;
  height: 88px;
}

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.head > span {
  font-size: 18px;
  color: #333333;
  font-weight: bold;
  padding-top: 30px;
}

.forminput {
  width: 356px;
  margin: -50px 0 0 0;
}

.ant-input {
  height: 48px;
  font-size: 14px;
  color: #868686;
}

.ant-btn {
  width: 356px;
  height: 50px;
  background: #f44600;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 16px;
  margin-top: -70px;
}

.bottom_desc {
  margin-top: 5%;
  font-size: 14px;
  color: #797979;
}
</style>
