<template>
  <div class="header">
    <div class="left">
      <img src="../../../assets/images/logo.png" alt="" />
    </div>
    <div class="center">
      <!-- 党员头部 -->
      <div class="sales">
        <a-menu mode="horizontal" :selectedKeys="[`${this.$route.matched[2].path}${this.$route.params.id ? '/' + this.$route.params.id : ''}`]">
          <a-menu-item key="/member/user">
            <router-link :to="{ path: '/member/user/list' }">用户管理 </router-link>
          </a-menu-item>
          <a-menu-item key="/member/task">
            <router-link :to="{ path: '/member/task/list' }">任务管理 </router-link>
          </a-menu-item>
          <a-menu-item key="/member/rank">
            <router-link :to="{ path: '/member/rank/list' }">排行榜单 </router-link>
          </a-menu-item>
        </a-menu>
      </div>
    </div>
    <div class="right">
      <span>欢迎回来, admin</span>
      <div class="logout" @click="logout">
        <img src="../../../assets/images/logout.png" alt="" />
        <span>退出登录</span>
      </div>
    </div>
    <!-- 退出弹框 -->
    <a-modal width="400px" height="300px" v-model="logoutDialogVisible" title="退出登录" footer="" centered @cancel="cancel">
      <div class="main">
        <p>是否退出登录</p>
        <!-- 底部按钮 -->
        <div class="bottom_btn">
          <a-button class="cancel" @click="logoutDialogVisible = false">取消</a-button>
          <a-button class="save" @click="save">确定</a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      admin: '平台管理员',
      institution: '机构名称',
      current: ['coach'],
      role: '',
      logoutDialogVisible: false
    }
  },
  created() {},
  methods: {
    logout() {
      // 清除token
      this.logoutDialogVisible = true
    },
    save() {
      localStorage.removeItem('token')
      this.$message.success('退出登录成功')
      this.$router.push('/login')
    },
    cancel() {}
  }
}
</script>

<style scoped>
.header {
  position: fixed;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  right: 0;
  min-width: 1300px;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04);
}
.left {
  display: flex;
  padding: 15px 30px;
}
.left p {
  padding-left: 20px;
  font-size: 22px;
  color: #fff;
}
.right {
  padding-right: 30px;
  display: flex;
  align-items: center;
}
.span {
  width: 1px;
  height: 20px;
  margin: 0 20px;
  background: #e4e4e4;
}
.right span {
  color: #333333;
}
.center {
  display: flex;
  align-items: center;
}
.center >>> .ant-menu {
  border: none;
}
.center >>> .ant-menu-submenu {
  background: #fff;
  color: #222222;
}
.center >>> .ant-menu-item {
  text-align: center;
  color: #222222 !important;
}
.center >>> .ant-menu-submenu {
  margin: 0 30px;
  color: rgba(255, 255, 255, 0.5);
}
.center >>> .ant-menu-submenu-selected {
  color: #222222 !important;
  border-bottom: 2px solid #f44600;
}
.center >>> .ant-menu-submenu:hover {
  color: #222222 !important;
  border-bottom: 2px solid #f44600;
}
.center >>> .ant-menu-submenu-title:hover {
  color: #222222;
}
.center >>> .ant-menu-submenu-content {
  position: absolute;
  left: 50%;
}
.center >>> .ant-menu:hover {
  color: #222222;
}
.sales >>> .ant-menu-item {
  margin: 0 20px;
}
.sales >>> .ant-menu-item:hover {
  border-bottom: 2px solid #f44600;
}
.sales >>> .ant-menu-item:hover > a {
  color: #222222;
}
.sales >>> .ant-menu-item > a {
}
.sales >>> .ant-menu-item-selected {
  border-bottom: 2px solid #f44600;
}
.sales >>> .ant-menu-item-selected > a {
  color: #222222;
}
.logout {
  cursor: pointer;
  color: #f44600;
  width: 90px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid #868fa3;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}
.main > p {
  margin: 26px 0;
  text-align: center;
}
.bottom_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.cancel {
  width: 88px;
  height: 36px;
  background: #ffffff;
  border: 1px solid #d9dbde;
  font-size: 14px;
  color: #666666;
}
.save {
  width: 88px;
  height: 36px;
  background: #f44600;
  font-size: 14px;
  color: #ffffff;
  margin-left: 20px;
}
* >>> .ant-modal-title {
  font-weight: bold;
  font-size: 16px;
  color: #f44600;
}
</style>
